import "./contact.css";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import BusinessIcon from "@mui/icons-material/Business";
import { Box, Button, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import SendIcon from "@mui/icons-material/Send";
import { motion } from "framer-motion";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const ContactComp = () => {
  const [EmailContent, setEmailContent] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [validEmailError, setvalidEmailError] = useState(false);

  const ref = useRef();

  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    setEmailError(false);
    setMessageError(false);
    setNameError(false);
    setvalidEmailError(false);
    e.preventDefault();

    const { name, email, message } = EmailContent;

    if (
      name === "" ||
      email === "" ||
      message === "" ||
      !email.match(emailRegex)
    ) {
      if (name === "") setNameError(true);

      if (email === "") setEmailError(true);

      if (!email.match(emailRegex)) setvalidEmailError(true);

      if (message === "") setMessageError(true);

      return;
    } else {
      emailjs
        .sendForm(
          "service_iv97byj",
          "template_znx6742",
          ref.current,
          "Z_4p0Iwaho4QrEPEO"
        )
        .then(
          (result) => {
            console.log(result.text);
            setSuccess(true);
            setEmailContent({ name: "", email: "", message: "" });
            setTimeout(() => {
              setSuccess(false);
            }, 3000); // Set to hide after 3 seconds (3000 milliseconds)
          },
          (error) => {
            console.log(error.text);
            setSuccess(false);
          }
        );
    }
  };

  return (
    <div className="contactinfo-section" style={{ overflow: "hidden" }}>
      <motion.div
        whileInView={{ x: [+300, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5, ease: "linear" }}
        id="Contactinfo-title"
      >
        <p>HARVESTING PROSPERITY</p>
        <h1>Feel Free To Contact</h1>
      </motion.div>
      <div id="contactinfo_main">
        <div className="Contactinfo_container">
          <div id="Contactinfo_container-description">
            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
              
            >
             <PhoneInTalkIcon className="contactIcon" />
              <p>530-870-1553</p>
            </motion.div>

            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
            >

<PhoneInTalkIcon className="contactIcon" />
              <p>530-632-9424</p>
            </motion.div>

            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
            >


              <PhoneInTalkIcon className="contactIcon" />
              <p>530-777-9686</p>
            </motion.div>

            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
            >
              <PhoneInTalkIcon className="contactIcon" />
              <p>Fax : 530-923-2182</p>
            </motion.div>

            

            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
            >
              <EmailIcon className="contactIcon" />
              <p>servemax73@gmail.com</p>
            </motion.div>

            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
            >
              <LanguageIcon className="contactIcon" />
              <p>servemaxinc.com</p>
            </motion.div>

            <motion.div
              whileInView={{ x: [-300, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
            >
              <BusinessIcon className="contactIcon" />
              <p>P.O. Box 3715, Yuba City, CA95992</p>
            </motion.div>
          </div>
        </div>
        <motion.div
          whileInView={{ x: [+300, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5, delay: 0.1, ease: "linear" }}
          className="ContactForm"
        >
          <EmailIcon className="emailIcon" />
          <Box
            component="form"
            ref={ref}
            noValidate
            autoComplete="off"
            onSubmit={sendEmail}
            className="emailbox"
          >
            <TextField
              label="Name"
              required
              variant="filled"
              fullWidth
              margin="dense"
              color="success"
              error={nameError}
              helperText={nameError ? "Please Enter Your Name" : ""}
              sx={{
                borderRadius: "10px",
              }}
              InputProps={{
                style: {
                  background: "rgb(241, 241, 241,0.6)",
                  fontFamily: "'PT Serif', serif",
                },
              }}
              name="name"
              value={EmailContent.name}
              onChange={(e) => {
                setEmailContent({ ...EmailContent, name: e.target.value });
              }}
            />

            <TextField
              label="Email"
              required
              variant="filled"
              fullWidth
              type="email"
              margin="dense"
              color="success"
              error={emailError || validEmailError}
              helperText={
                emailError
                  ? "Please Enter Your Email"
                  : validEmailError
                  ? "Please Enter A Valid Email"
                  : ""
              }
              sx={{
                borderRadius: "10px",
              }}
              InputProps={{
                style: {
                  background: "rgb(241, 241, 241,0.6)",
                  fontFamily: "'PT Serif', serif",
                },
              }}
              name="email"
              value={EmailContent.email}
              onChange={(e) => {
                setEmailContent({ ...EmailContent, email: e.target.value });
              }}
            />
            <TextField
              label="Message"
              variant="filled"
              required
              fullWidth
              margin="dense"
              color="success"
              multiline
              error={messageError}
              helperText={messageError ? "Message Cannot Be Empty" : ""}
              rows={4}
              sx={{
                borderRadius: "10px",
              }}
              InputProps={{
                style: {
                  background: "rgb(241, 241, 241,0.6)",
                  fontFamily: "'PT Serif', serif",
                },
              }}
              name="message"
              value={EmailContent.message}
              onChange={(e) => {
                setEmailContent({ ...EmailContent, message: e.target.value });
              }}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{
                marginTop: "1.5rem",
                backgroundColor: "#02b65f",
                fontFamily: "'PT Serif', serif",
                "&:hover": {
                  backgroundColor: "#018d49",
                },
              }}
              endIcon={<SendIcon />}
            >
              Submit
            </Button>

            {success && (
              <div
                style={{
                  color: "Black",
                  marginTop: "5px",
                  textTransform: "capitalize",
                }}
              >
                Your message is Received!
              </div>
            )}
          </Box>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactComp;
