import React from "react";
import "./gallery.css";
import { motion } from "framer-motion";

import gallery1 from "../ASSETS/gallery1.png";
import gallery2 from "../ASSETS/gallery2.jpg";
import gallery3 from "../ASSETS/gallery3.JPG";
import gallery4 from "../ASSETS/gallery4.png";

import { Box, Grid } from "@mui/material";
export default function Gallery() {
  return (
    <Box className="galeryHeader" sx={{ overflow: "hidden" }}>
      <motion.div
        whileInView={{ x: [+300, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
        className="heading"
      >
        <h1>
          Loved by Farmers <br />
          Trusted by Communities
        </h1>
      </motion.div>

      <div>
        <Grid container spacing={0} className="grid_container">
          <Grid item md={3} sm={6} xs={12} className="grid_item">
            <motion.div
              whileInView={{ scale: [0, 1], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
            >
              <img src={gallery1} alt="" className="pic" />
            </motion.div>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="grid_item">
            <motion.div
              whileInView={{ scale: [0, 1], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
            >
              <img src={gallery2} alt="" className="pic" />
            </motion.div>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="grid_item">
            <motion.div
              whileInView={{ scale: [0, 1], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.4, ease: "linear" }}
            >
              <img src={gallery3} sm={6} xs={12} alt="" className="pic" />
            </motion.div>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="grid_item">
            <motion.div
              whileInView={{ scale: [0, 1], opacity: [0, 1] }}
              transition={{ duration: 0.5, delay: 0.5, ease: "linear" }}
            >
              <img src={gallery4} alt="" className="pic" />
            </motion.div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
