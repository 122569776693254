import { Button } from "@mui/material";
import "./homelanding.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";

const HomeLanding = () => {
  function scrollToDiv(e) {
    e.preventDefault();
    var offset = 35;

    const targetElement = document.getElementById("Services");

    if (targetElement) {
      const offsetPosition = targetElement.offsetTop - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
  return (
    <div className="HomeLandingContainer" style={{ overflow: "hidden" }}>
      <div className="home-section">
        <motion.h1
          whileInView={{ x: [-300, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
          style={{ overflow: "hidden" }}
        >
          Connecting Farmers with Skilled Labor
        </motion.h1>
        <motion.h6
          whileInView={{ x: [-300, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
          style={{ overflow: "hidden" }}
        >
          {" "}
          With a deep commitment to sustainability and productivity, we offer a
          wide range of labor services tailored to your needs. Experience the
          difference when you partner with us to turn your agricultural dreams
          into reality.
        </motion.h6>

        <motion.div
          whileInView={{ scale: [0, 1], opacity: [0, 1] }}
          transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
          className="Btndiv"
        >
          <Button
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            onClick={(e) => scrollToDiv(e)}
            sx={{
              backgroundColor: "#02b65f",
              borderRadius: "10179.125px",
              fontFamily: "'PT Serif', serif",
              padding: "1% 3% 1%",
              "&:hover": {
                backgroundColor: "#018d49",
              },
            }}
          >
            Our Services
          </Button>
        </motion.div>
      </div>
      <img srcSet="/Images/background.svg" alt="" className="homeImg" />
    </div>
  );
};

export default HomeLanding;
