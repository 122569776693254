import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "./navbar.css";

const pages = ["Home", "Gallery", "Services", "About us", "Contact us"];

function scrollToDiv(e, targetId) {
  e.preventDefault();
  var offset = 35;

  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    const offsetPosition = targetElement.offsetTop - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

function Navbar() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleClick(e, targetId) {
    e.preventDefault();
    scrollToDiv(e, targetId);
    handleDrawerClose();
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          background: "rgb(255,255,255,0.6)",
          backdropFilter: "blur(5px)",
          zIndex: "100",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* normal view header */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                justifyContent: "center",
                color: "black",
                textDecoration: "none",
                width: "10.1%",
              }}
            >
              <img className="logo" srcSet="/Images/logo2.png" alt="" />
            </Typography>

            {/* Mobile View */}

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerOpen}
                edge="start"
                color="inherit"
                sx={{ color: "black" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Typography
              noWrap
              component="a"
              href="/"
              sx={{
                display: { xs: "flex", md: "none" },

                mr: "39.5%",
              }}
            >
              <img className="logo" srcSet="/Images/logo2.png" alt="ServeMax" />
            </Typography>

            {/*  NORMAL VIEW */}

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",

                marginRight: "7%",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={(e) => scrollToDiv(e, page)}
                  sx={{
                    color: "black",
                    display: "block",
                    marginRight: "2rem",
                    fontFamily: "'PT Serif', serif",
                    fontWeight: "600",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        PaperProps={{
          className: "drawer",
          sx: {
            display: "flex",
            width: "18rem",
            boxShadow: "1px 1px 10px 0px #02b65f",
            backdropFilter: "blur(20px)",
            color: "black",
            background: "transparent",
          },
        }}
      >
        <CloseIcon
          fontSize="medium"
          sx={{
            marginTop: "1rem",
            padding: "1rem 1rem 1rem",
          }}
          onClick={handleDrawerClose}
        />

        <List>
          {pages.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={(e) => handleClick(e, text)}>
                <ListItemText primary={text} sx={{ textAlign: "left" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
export default Navbar;
