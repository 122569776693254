import "./App.css";
import HomeLanding from "./Pages/homeLanding/HomeLanding";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Navbar from "./Component/navbar/Navbar";
import ContactComp from "./Pages/ContactUs/contact";
import Gallery from "./Pages/Gallery/Gallery";
import Services from "./Pages/Services/Services";
import About from "./Pages/About/About";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div id="Home">
        <HomeLanding />
      </div>
      <div id="Gallery">
        <Gallery />
      </div>

      <div id="Services">
        <Services />
      </div>

      <div id="About us">
        <About />
      </div>

      <div id="Contact us">
        <ContactComp />
      </div>

      <FloatingWhatsApp
        phoneNumber="+15308701553"
        accountName="Serve Max"
        avatar="/Images/logo.svg"
        statusMessage="Online"
        allowClickAway={true}
        notification={true}
        notificationSound={true}
        messageDelay={1}
        notificationDelay={2}
      />
    </div>
  );
}

export default App;
