import React from "react";
import "./services.css";
import tomato from "./Tomato.svg";
import flower from "../ASSETS/flower.png";
import pistachio from "../ASSETS/pistachio.png";
import benefits from "../ASSETS/benefits.png";
import Irrigation from "../ASSETS/Irrigation.png";
import grapes from "../ASSETS/grapes.png";
import nursery from "../ASSETS/Nursery.png";
import almond from "../ASSETS/almond.png";
import leaf from "../ASSETS/our_services.png";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";

export default function Services() {
  return (
    <div className="service_main">
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, ease: "linear" }}
      >
        <img src={leaf} alt="" className="leafIMg" />
      </motion.div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <motion.div
          whileInView={{ x: [+300, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
          style={{ position: "relative", zIndex: "2", overflow: "hidden" }}
          className="services_text"
        >
          <p className="small_txt"> HARVESTING PROSPERITY</p>
          <h1 className="text1">Our Comprehensive Agriculture Services </h1>
        </motion.div>
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
          className="services_offered"
        >
          <Grid container spacing={0} className="gridContainer">
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={tomato} alt="Tomato" />
                <p>Tomatoes Transplanting</p>
                <p> Tomatoes Hoeing</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={flower} alt="flower" />
                <p>Roguing sunflower</p>
                <p>Hoeing sunflower</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={pistachio} alt="Tomato" />
                <p>Pistachio harvest</p>
                <p>Pistachio Prunning</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={benefits} alt="Tomato" />
                <p>Marking Walnut Orchards</p>
                <p> Walnut Harvest </p>
                <p>Walnet Hauler</p>
                <p> Walnut Pruning</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={almond} alt="Almond" />
                <p>Marking Almond Orchards</p>
                <p> Almond Harvest </p>
                <p>Almond Hauler</p>
                <p> Almond Pruning</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img
                  src={Irrigation}
                  alt="Irrigation"
                  className="irrigationimg"
                />
                <p>Irrigation</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={grapes} alt="Grapes" />
                <p>Planting Grapes</p>
              </motion.div>
            </Grid>
            <Grid item md={3} sm={6} xs={12} className="itemGrid_section">
              <motion.div
                whileInView={{ scale: [0, 1], opacity: [0, 1] }}
                transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
              >
                <img src={nursery} alt="Nursery" />
                <p>Nursery</p>
              </motion.div>
            </Grid>
          </Grid>
        </motion.div>
      </div>
    </div>
  );
}
