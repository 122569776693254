import React from "react";
import "./about.css";
import { motion } from "framer-motion";

export default function About() {
  return (
    <div className="main_about">
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delay: 0.3, ease: "linear" }}
        className="grain"
      ></motion.div>

      <motion.div
        whileInView={{ scale: [0, 1], opacity: [0, 1] }}
        transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
        className="header_div"
      >
        <p className="header_text">About us</p>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, ease: "linear" }}
        className="aboutSectionDiv"
        style={{ position: "relative", zIndex: "1" }}
      >
        <div className="about_text">
          <motion.p
            whileInView={{ x: [-300, 0], opacity: [0, 1] }}
            transition={{ duration: 0.5, delay: 0.2, ease: "linear" }}
            className="txt"
          >
            Serve Max is a Farm Labor Contractor with years of experience in the
            Farm Labor field, doing business in the area of Sacramento,
            Woodland, Yuba City and other North California Counties. We (SERVE
            MAX) have worked with farmers’ companies such as Pioneer DoPont,
            Nagra Farms, US Agriseeds, Hermle Farms and among other great
            farmers.
            <br />
            <br />
            Serve Max works with almost any kind of crops, fruit trees and
            vineyards, providing services such as picking, pruning, thinning,
            tying, hoeing, planting, transplanting, Packing houses, nurseries
            and other miscellaneous.
            <br />
            <br />
            In Serve Max all employees are important, that is why we are covered
            with the Workers Compensation Insurance and the Illness Prevention
            Program. We have all the permits and license to perform our job and
            we also have a General Liability Insurance.
          </motion.p>
        </div>
      </motion.div>
    </div>
  );
}
